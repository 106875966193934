import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './avatar.css';
import MyAvatar from './MyAvatar';
import ItemList from './ItemList';
import ItemCategory from './ItemCategory';


function Inventory (){

    const [아이템카테고리,아이템카테고리변경] = useState();
    const [아이템,아이템변경] = useState({});
    const [인벤토리,인벤토리변경] = useState([]);


    useEffect(()=>{
        let postData ={
                    'method':'item',
                    'data':{'method':'memberAvatar'}
                }
        
        const fetchEvent =async() => {
            const res = await axios.post('https://seosanez.project.wo.tc/yamyam.php',JSON.stringify(postData));
            makeAvatar(res.data.result);
        }


        const makeAvatar = (items)=>{
            아이템변경(items)
        }
        fetchEvent();
    },[]);

    useEffect(()=>{
        let postData ={
                'method':'item',
                'data':{'method':'adminItemList','category':아이템카테고리}
            }

        
        const fetchItemListLoad = async()=>{
            const inventoryRes = await axios.post('https://seosanez.project.wo.tc/yamyam.php',JSON.stringify(postData));
            makeInventory(inventoryRes.data.result);
        }
        const makeInventory = (items)=>{
            console.log(items);
            인벤토리변경(items)
        }
        fetchItemListLoad();
    },[아이템카테고리]);

    function selectItem(filePath,indexKey){
    
        const aaa = {...아이템};

        if(indexKey==='fronthair'){
            aaa.fronthair = filePath;
        }else if (indexKey==='rearhair'){
            aaa.rearhair = filePath;
        }else if (indexKey==='dress'){
            aaa.dress = filePath;
        }else if (indexKey==='earitem'){
            aaa.earitem = filePath;
        }else if (indexKey==='eye'){
            aaa.eye = filePath;
        }else if (indexKey==='eyebrow'){
            aaa.eyebrow = filePath;
        }else if (indexKey==='outerdress'){
            aaa.outerdress = filePath;
        }else if (indexKey==='mouth'){
            aaa.mouth = filePath;
        }else if (indexKey==='nose'){
            aaa.nose = filePath;
        }else if (indexKey==='pet'){
            aaa.pet = filePath;
        }else if (indexKey==='hairitem'){
            aaa.hairitem = filePath;
        }else if (indexKey==='eyeitem'){
            aaa.eyeitem = filePath;
        }
        아이템변경(aaa);
    }

    const avatarMount = <MyAvatar rearhair={아이템.rearhair} 
            body={아이템.body} 
            fronthair={아이템.fronthair}
            dress={아이템.dress}
            outerdress={아이템.outerdress}
            eyeitem={아이템.eyeitem}
            face={아이템.face}
            eye={아이템.eye}
            eyebrow={아이템.eyebrow}
            mouth={아이템.mouth}
            nose={아이템.nose}
            hairitem={아이템.hairitem}
            pet={아이템.pet}
            earitem={아이템.earitem}/>

    const mapToItemComponent = 인벤토리.map((items,key)=>
            <span onClick={()=>selectItem(items.filePath,아이템카테고리)}>
                <ItemList tem={items.filePath} />
            </span>
            
        );

    const changeInventory=(coco)=>{

        아이템카테고리변경(coco);
        console.log(coco);
    }

    return(
        <>
            <div className="Avatar" >
                {avatarMount}
            </div>
            <div className="Item">
                {mapToItemComponent}
            </div>
            <div className="Category">
                <ItemCategory changeInvent={changeInventory} name={'showmethemoney'} />
            </div>
        </>);
}


export default Inventory;