import React,{useState, useEffect} from 'react';
import axios from 'axios';
import {useCookies} from 'react-cookie';


const ManagerRoot = ()=>{

    const [cookies,setCookies] = useCookies(['PHPSESSID']);
    const [로긴,로긴변경] = useState({
        id:'',
        pw:cookies,
        loginOk:true
    });

    useEffect(()=>{

        let postData ={
            'method':'signup',
            'data':{
                'method':'isLogin'}
            }

        const initRender = async()=>{
            axios.interceptors.request.use(
                function (config) {
                  // 요청을 보내기 전에 수행할 일
                  // ...
                  console.log(config);
                  return config;
                },
                function (error) {
                  // 오류 요청을 보내기전 수행할 일
                  // ...
                  return Promise.reject(error);
                });
            axios.interceptors.response.use(
                function (response) {

                    // console.log(response);
                  // 응답 데이터를 가공
                  // ...
                  return response;
                },
                function (error) {
                  // 오류 응답을 처리
                  // ...
                  return Promise.reject(error);
                });

            axios.post('./yamyam.php',JSON.stringify(postData),{withCredentials: true}).then(
                (response)=>{
                    let 초기로긴여부 = {...로긴};
                    초기로긴여부.loginOk = response.data.noneListResult.loginOk;
                    로긴변경(초기로긴여부);
                }
            );
        }
        initRender();

    },[]);// eslint-disable-line

    

    const loginChecked = (e)=>{


        console.log(e);
        if(e.id.length > 2){
            let postData ={
                'method':'signup',
                'data':{
                    'method':'login',
                    'email':e.id,
                    'passwd':e.pw}
            }
    
            const reRender = async()=>{
                axios.post('./yamyam.php',JSON.stringify(postData),{ withCredentials: true}).then(
                    (response)=>{
                        makeInventory(response);
                        console.log(response.request);
                    }
                );
            }
            const makeInventory = (items)=>{
                const res = items.data.noneListResult;
                let 임시로긴 = {...로긴};
                임시로긴.loginOk = res.loginOk;
                임시로긴.id = res.PHPSESSID;
                로긴변경(임시로긴);
            }

            reRender();
        }
    }

    useEffect(()=>{
        console.log(로긴);
    },[로긴]);

    


    const LoginForm = ({onLogined})=>{

        const [로긴데이터,로긴데이터변경] = useState({
            id:'',
            pw:'',
            loginOk:false
        });

        return(<>
        <form onSubmit={(e)=>{
            e.preventDefault();
            onLogined(로긴데이터);
            }}>
            <div>
                <span>id</span><span><input type="text" value={로긴데이터.id} placeholder="id" onChange={(e)=>{
                    let 입력  = {...로긴데이터};
                    입력.id = e.target.value;
                    로긴데이터변경(입력);
                }} /></span>
            </div>
            <div>
                <span>pw</span><span><input type="password" value={로긴데이터.pw} placeholder="pw" onChange={(e)=>{
                    let 입력  = {...로긴데이터};
                    입력.pw = e.target.value;
                    로긴데이터변경(입력);
                }} /></span>
            </div>
            <div><button type="submit" >로그인</button></div>
        </form>
        </>);
    }

    return(<>
        <div>
        {로긴.loginOk
        ? 로긴.id 
        : <LoginForm onLogined={loginChecked}/>
        }
            
        </div>
    </>
    );
}

export default ManagerRoot;