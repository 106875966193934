import React from 'react';


const NoticeSetting = ()=>{
    
    return(<>
        <div>
            NoticeSetting
        </div>
    </>);
}

export default NoticeSetting;