import {Route} from "react-router-dom"
import Home from './avatar/Root';
import Avatar from './avatar/Avatar';
import Inventory from './avatar/Inventory';
import './App.css';
import Manager from "./manager/Manager";

function App() {
  


  return (
    <div className="App">
      <Route path="/" component={Home} exact/>
      <Route path="/avatar" component={Avatar} exact />
      <Route path="/avatar/inventory" component={Inventory} exact />
      <Route path="/manager" component={Manager} exact />
    </div>
  );
}

export default App;
