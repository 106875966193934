import React, { Component } from 'react';
import './itemlist.css';
import { BrowserRouter } from 'react-router-dom';


class ItemList extends Component{


    render(){
        return (
            <BrowserRouter>
                <div className="ItemList">
                    <img className="ItemObject" src={'https://seosanez.project.wo.tc/'+this.props.tem} alt="tem" />
                </div>
            </BrowserRouter>
        );
        
    };
};


export default ItemList;