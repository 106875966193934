import React, { Component } from 'react';
import './myavatar.css';
import { BrowserRouter } from 'react-router-dom';


class MyAvatar extends Component{


    render(){
        return (
            <BrowserRouter>
            
                <div className="MyAvatar" >
                    <div className="MyItem" >
                        <img className="MyItemSrc" src={'https://seosanez.project.wo.tc/'+this.props.rearhair} alt="rearhair" />
                    </div>
                    <div className="MyItem" >
                        <img className="MyItemSrc" src={"https://seosanez.project.wo.tc/"+this.props.body} alt="body" />
                    </div>
                    <div className="MyItem" >
                        <img className="MyItemSrc" src={"https://seosanez.project.wo.tc/"+this.props.dress} alt="asdf" />
                    </div>
                    <div className="MyItem" >
                        <img className="MyItemSrc" src={"https://seosanez.project.wo.tc/"+this.props.outerdress} alt="outerdress" />
                    </div>
                    <div className="MyItem" >
                        <img className="MyItemSrc" src={"https://seosanez.project.wo.tc/"+this.props.earitem} alt="earitem" />
                    </div>
                    <div className="MyItem" >
                        <img className="MyItemSrc" src={"https://seosanez.project.wo.tc/"+this.props.face} alt="face" />
                    </div>
                    <div className="MyItem" >
                        <img className="MyItemSrc" src={"https://seosanez.project.wo.tc/"+this.props.eyebrow} alt="asdf" />
                    </div>
                    <div className="MyItem" >
                        <img className="MyItemSrc" src={"https://seosanez.project.wo.tc/"+this.props.eye} alt="asdf" />
                    </div>
                    <div className="MyItem" >
                        <img className="MyItemSrc" src={"https://seosanez.project.wo.tc/"+this.props.mouth} alt="mouth" />
                    </div>
                    <div className="MyItem" >
                        <img className="MyItemSrc" src={"https://seosanez.project.wo.tc/"+this.props.nose} alt="asdf" />
                    </div>
                    <div className="MyItem" >
                        <img className="MyItemSrc" src={"https://seosanez.project.wo.tc/"+this.props.fronthair} alt="asdf" />
                    </div>
                    <div className="MyItem" >
                        <img className="MyItemSrc" src={"https://seosanez.project.wo.tc/"+this.props.hairitem} alt="hairitem" />
                    </div>
                    <div className="MyItem" >
                        <img className="MyItemSrc" src={"https://seosanez.project.wo.tc/"+this.props.eyeitem} alt="eyeitem" />
                    </div>
                    <div className="MyItem" >
                        <img className="MyItemSrc" src={"https://seosanez.project.wo.tc/"+this.props.pet} alt="pet" />
                    </div>
                </div>
            </BrowserRouter>
        );
        
    };
};


export default MyAvatar;