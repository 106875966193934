import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import '../App.css';
import pulseLogo from '../resource/logo.png';
import phoneImg from '../resource/main.png';
import downloadbt from '../resource/downloadbtn.png';
import downloadbt2 from '../resource/downloadbtn2.png';

const Root = () => {
    return (
        <BrowserRouter>
            <div className="App">
                <div className="AppHeader">
                    <div className="AppHeaderIn">
                        <div className="HeaderContents1">
                            <div className="HeaderInnerContents1">
                                <img src={pulseLogo} alt="이미지 불러오기에 실패하였습니다." />
                            </div>
                            <div className="HeaderInnerContents2">
                                <div className="HeaderText">
                                    입점문의
                                </div>
                                <div className="HeaderText">
                                    고객센터
                                </div>                
                            </div>
                        </div>
                    </div>
                </div>
                <div className="AppBody">
                    <div className="AppBodyIn">
                        <div className="AppBodyContents1">
                        <div className="BodyContents1">
                            서산시 배달/커뮤니티 APP
                        </div>

                        <div className="BodyContents2">
                            곱빼기
                        </div>

                        <div className="BodyContents3">
                            <div>
                            소비자들의 만족감을 곱하고,
                            </div>
                            <div>
                            소상공인들의 부담을 덜다!
                            </div>
                        </div>
                        <div className="BodyContents4">
                            <div className="BtDiv">
                            <a href="https://play.google.com/store/apps/details?id=tc.wo.pulse.community">
                                <img className="BtDown1" src={downloadbt} alt="이미지 불러오기에 실패하였습니다."/>
                            </a>
                            </div>

                            <div className="BtDiv">
                            <img className="BtDown2" src={downloadbt2} alt="이미지 불러오기에 실패하였습니다."/>
                            </div>
                        </div>
                        </div>
                        <div className="AppBodyContents2">
                        <img className="PhoneImg" src={phoneImg} alt="이미지 불러오기에 실패하였습니다."/>
                        </div>
                    </div>
                </div>
                <div className="AppBodyFooter">
                    <div className="FooterContents">
                        <div className="FooterInnerContents1">
                            <div className="FooterPulseBold">PULSE</div>
                                <a href="https://www.ftc.go.kr/bizCommPop.do?wrkr_no=5012347453">(사업자정보확인)</a>
                            </div>
                        <div className="FooterInnerContents1">충청남도 서산시 안견로 292</div>
                    </div>
                <div className="FooterContents">
                    <div className="FooterInnerContents2">대표자 : 이인범 | 사업자번호 : 501-23-47453</div>
                    <div className="FooterInnerContents2">통신판매업 : 2021-충남서산-0066</div>
                </div>
                <div className="FooterContents">
                    
                    <div className="FooterInnerContents2">대표전화 : 012-3456-7890</div>
                    <div className="FooterInnerContents2">대표메일 : leeinbum@gmail.com</div>
                </div>
                </div>
            </div>
        </BrowserRouter>
    );
};

export default Root;