import axios from 'axios';
import React from 'react';
import { useEffect, useState } from 'react';


const OrderList = ()=>{

    const [orderListData, setOrderListData] = useState({
        returnCode:'9999',
        result:{noticeList:[]}
    });
    
    

    useEffect(()=>{
        let postData ={
            'method':'notice',
            'data':{
                'method':'noticeList'}
        }
        const render = async()=>{
            axios.post('https://seosanez.project.wo.tc/pulseManager.php',JSON.stringify(postData),{ withCredentials: true}).then(
                (response)=>{
                    setOrderListData(response.data);
                    console.log(response.data.result.noticeList);
                    // makeInventory(response);
                }
            );
        };

        render();
        
    },[]);

    const OrderHeader = ()=>{

        return (<>
        orderHeader
        </>);
    }
    const OrderBody = ()=>{

        const noticeList = orderListData.result.noticeList.map(item=><div key={item.noticeSrl}>{item.title}{item.contents}{item.lastUpdateDate}{item.noticeSrl}{item.regdate}</div>);//orderListData.result.noticeList.map(values=><div>{values.contents}</div>);

        return (<>
        <div>
            {noticeList}
        </div>
        </>);
    }
    
    return(<>
        <OrderHeader />
        <OrderBody />
    </>);
}

export default OrderList;