import React from 'react';
import NoticeSetting from './NoticeSetting';
import OrderList from './OrderList';
import { BrowserRouter, Switch, Route, Link } from 'react-router-dom';
import DashBoard from './DashBoard';
import AvatarSetting from './AvatarSetting';
import ManagerRoot from './ManagerRoot';


const Manager = ()=>{


    const ManagerHeader = ({menu})=>{
        

        return(<>
            <div>   
                <Link to="/manager" >메인 </Link> / <Link to="/manager/orderlist">주문내역</Link> / <Link to="/manager/avatar">아바타설정</Link> / <Link to="/manager/notice">공지사항설정</Link> / <Link to="/manager/dash">매출현황표</Link>
            </div>
        </>);
    }

    return(<BrowserRouter>
        <ManagerHeader />
        <Switch>
            <Route path="/manager" exact >
                <ManagerRoot />
            </Route>
            <Route path="/manager/dash" >
                <DashBoard />
            </Route>
            <Route path="/manager/notice" >
                <NoticeSetting />
            </Route>
            <Route path="/manager/orderlist">
                <OrderList />
            </Route>
            <Route path="/manager/avatar">
                <AvatarSetting />
            </Route>
        </Switch>
        </BrowserRouter>
        );
}

export default Manager;