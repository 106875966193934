import React from 'react';
import './avatar.css';


const ItemCategory = (props)=>{


    const getInvent=(cate)=>{
        props.changeInvent(cate);
    }


    return(
        <>
        <div className="Category" >
            <div className="CategoryItem" onClick={()=>getInvent('rearhair')}>
                뒷머리
            </div>
            <div className="CategoryItem" onClick={()=>getInvent('fronthair')}>
                앞머리
            </div>
            <div className="CategoryItem" onClick={()=>getInvent('nose')}>
                코
            </div>
            <div className="CategoryItem" onClick={()=>getInvent('mouth')}>
                입
            </div>
            <div className="CategoryItem" onClick={()=>getInvent('hairitem')}>
                머리장식
            </div>
            <div className="CategoryItem" onClick={()=>getInvent('earitem')}>
                귀장식
            </div>
            <div className="CategoryItem" onClick={()=>getInvent('dress')}>
                옷
            </div>
            <div className="CategoryItem" onClick={()=>getInvent('outerdress')}>
                겉옷
            </div>
            <div className="CategoryItem" onClick={()=>getInvent('pet')}>
                펫
            </div>
            <div className="CategoryItem" onClick={()=>getInvent('eyeitem')}>
                눈장식
            </div>
            <div className="CategoryItem" onClick={()=>getInvent('eyebrow')}>
                눈썹
            </div>
        </div>
        </>
    );
}

export default ItemCategory;
